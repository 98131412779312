import React from 'react'
import { graphql } from 'gatsby'
import { Head } from 'src/components/Layout'
import { getLayouts } from 'src/lib/layout/getLayouts'
import { Cover, Footer, Related } from 'src/components/Post'

interface PostProps {
  errors?: any
  data?: GatsbyTypes.WpPostQuery
}

export default function Post({ data, errors }: PostProps): JSX.Element {
  errors && console.error(process.env.NODE_ENV === 'development' && errors)

  return (
    <>
      <Head
        meta={data?.wpPost?.ACF?.meta}
        page={{
          __typename: 'WpPost',
          title: data?.wpPost?.title || '',
          uri: data?.wpPost?.uri || '',
        }}
      />
      <Cover
        categories={data?.wpPost?.categories?.nodes}
        title={data?.wpPost?.title}
        date={data?.wpPost?.date}
        cover={data?.wpPost?.cover}
      />
      {getLayouts(data?.wpPost?.ACF?.layouts)}
      <Footer
        source={data?.wpPost?.source}
        url={`${process.env.GATSBY_SITE_URL}${data?.wpPost?.uri}`}
      />
      <Related title="Related articles" posts={data?.relatedPosts.nodes} />
    </>
  )
}

export const postQuery = graphql`
  query WpPost($id: String!) {
    wpPost(id: { eq: $id }) {
      __typename
      id
      title
      uri
      date
      cover {
        image {
          desktop {
            ...DesktopImageFields
          }
          mobile {
            ...MobileImageFields
          }
        }
      }
      categories {
        nodes {
          id
          name
          uri
        }
      }
      ACF {
        ...MetaFields
        ...LayoutFields
        ...OptionsFields
      }
      ...SourceFields
    }
    relatedPosts: allWpPost(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        ...PostSummaryFields
      }
    }
  }
`
